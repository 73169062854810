<template>

<app-content :loading="is.loading">

	<app-content-head :title="title" :back="$route.meta.back" :readonly="$authorised('con/schedule/access', 'read')">

		<app-content-head-actions-item disabled="!isDirty" icon="undo" text="Undo" v-on:click="onUndoClick" />
		<app-content-head-actions-item :loading="is.saving" :disabled="!isDirty || !isValid" icon="save" text="Save" v-on:click="onSaveClick" />
		<app-content-head-actions-item v-if="!isNew" :disabled="isNew" :danger="true" icon="delete" text="Delete" v-on:click="onDeleteClick" />

	</app-content-head>

	<app-content-body>

		<app-content-box :readonly="$authorised('con/schedule/access', 'read')" title="Setup" icon="settings">

			<app-input-text label="Name" placeholder="Enter name..." v-model="model.name" :validation="$v.model.name" />
			<app-input-toggle label="Type" v-model="model.type" :validation="$v.model.type" :options="$constants.schedule.contest.types" />
			<app-input-toggle label="Eligibility" v-model="model.qualification.enabled" :validation="$v.model.qualification.enabled" :options="$constants.schedule.contest.qualification.enabled" />
			<app-input-date v-if="model.type === $constants.schedule.contest.type.register" :time="true" label="Registration" placeholder="Enter register date..." :toggle="model.register.enabled" v-on:toggle="model.register.enabled = !model.register.enabled" v-model="model.register.date" :validation="$v.model.register.date" />
			<app-input-date :time="true" label="Starts" placeholder="Enter start date..." :toggle="model.from.enabled" v-on:toggle="model.from.enabled = !model.from.enabled" v-model="model.from.date" :validation="$v.model.from.date" />
			<app-input-date :time="true" label="Ends" placeholder="Enter end date..." :toggle="model.to.enabled" v-on:toggle="model.to.enabled = !model.to.enabled" v-model="model.to.date" :validation="$v.model.to.date" />
			<app-input-date :time="true" label="Awards" placeholder="Enter award date..." :toggle="model.auto.enabled" v-on:toggle="model.auto.enabled = !model.auto.enabled" v-model="model.auto.date" :validation="$v.model.auto.date" />
			
		</app-content-box>

		<app-content-box :readonly="$authorised('con/schedule/access', 'read')" v-if="model.qualification.enabled" title="Eligibility" icon="contests">

			<app-input-toggle label="Type" v-model="model.qualification.type" :validation="$v.model.qualification.type" :options="$constants.schedule.contest.qualification.types" />
			<app-input-toggle label="Sequence" v-model="model.qualification.order" :validation="$v.model.qualification.order" :options="$constants.schedule.contest.qualification.orders" />
			<app-input-text v-if="model.qualification.type === $constants.schedule.contest.qualification.type.threshold" label="Threshold" placeholder="Enter number..." v-model="model.qualification.threshold" :validation="$v.model.qualification.threshold" />

		</app-content-box>

		<app-content-box :readonly="$authorised('con/schedule/access', 'read')" title="About" icon="info">

			<app-input-text label="Description" :autogrow="true" placeholder="Enter description..." v-model="model.description" :validation="$v.model.description" />
			<app-input-image label="Image" placeholder="Enter image..." v-model="model.image" :validation="$v.model.image" />

		</app-content-box>

		<app-content-section v-if="!isNew" route="Convention.Schedule.Contest.Codes" :params="{ contest: item.id }" title="QR Codes" icon="checkout" description="Manage the QR codes that can be scanned to participate." :count="item.count.codes" />

		<app-content-section v-if="!isNew" route="Convention.Schedule.Contest.Prizes" :params="{ contest: item.id }" title="Prizes" icon="prize" description="Manage the prizes than can be won by eligible participants." :count="item.count.prizes" />

		<app-content-section v-if="!isNew" route="Convention.Schedule.Contest.Winners" :params="{ contest: item.id }" title="Winners" icon="winner" description="Manage the winners and manually award prizes." :count="item.count.winners" />

	</app-content-body>

</app-content>

</template>

<script>

import mixForm from '@/mixin/form'
import { required } from 'vuelidate/lib/validators'

export default {

	mixins: [mixForm],

	data: function() {

		return {
			readonly: this.$authorised('con/schedule/access', 'read'),
			model: {
				id: false,
				name: '',
				status: 0,
				type: 0,
				image: '',
				description: '',
				to: {
					enabled: 0,
					date: 0
				},
				auto: {
					enabled: 0,
					date: 0
				},
				register: {
					enabled: 0,
					date: 0
				},
				from: {
					enabled: 0,
					date: 0
				},
				qualification: {
					enabled: 0,
					type: 1,
					order: 1,
					threshold: 0
				}
			}
		}

	},

	validations: {
		model: {
			name: {
				required
			},
			description: {
				required
			},
			image: {},
			type: {
				required
			},
			to: {
				date: {}
			},
			register: {
				date: {}
			},
			auto: {
				date: {}
			},
			from: {
				date: {}
			},
			qualification: {
				enabled: {},
				type: {},
				threshold: {}
			}
		}
	}

}

</script>

<style scoped>

</style>